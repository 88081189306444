<template>
    <div
        role="alert"
        v-bind:class="{ show: errors.length }"
        class="alert fade alert-danger"
    >
        <div class="alert-text" v-for="(error, i) in errors" :key="i">
        {{ error }}
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "AuthErrors",
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    })
  }
}
</script>
